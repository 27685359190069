// event.selectors.ts

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { distanceBetween } from 'geofire-common';

import { ArkSearchBarFilter } from '../../../../../../ark/src/public-api';
import { selectUser } from '../auth/selectors';
import { EventState } from './state';

export const selectEventState = createFeatureSelector<EventState>('event');

export const selectEventByid = (id: string) =>
  createSelector(
    selectEventState,
    (state: EventState) => {
      return state.events[id];
      },
  );

export const selectEventsByUids = (ids: string[]) =>
  createSelector(
    selectEventState,
    (state: EventState) => ids.map((id) => state.events[id]).filter((event) => !!event),
  );


export const selectAllEvents = createSelector(
  selectEventState,
  (state) => {
    return Object.values(state?.events || []);
  },
);

export const selectAllEventsJoiners = createSelector(
  selectEventState,
  (state) => state.joiners,
);

export const selectAllEventsWithJoiners = createSelector(
  selectEventState,
  (state) => {
    const events = state.events;
    const joiners = state.joiners;
    return Object.values(events).map((e) => {
      if (joiners[e.id]) e.participants = joiners[e.id];
      return e;
    });
  },
);

export const selectEventsByAuthCreatorId = () =>
  createSelector(
    selectAllEvents,
    selectUser,
    (events, user) => events.filter((event) => event.creatorId === user.uid),
  );

export const selectEventsByCreatorId = (creatorId: string) =>
createSelector(
  selectAllEvents,
  (events) => events.filter((event) => event.creatorId === creatorId),
);

export const selectEventsByLatLngForAuthUser = (filter?: ArkSearchBarFilter) => createSelector(
  selectUser,
  selectFilteredEvents(filter),
  (user, events) => {
    if (!user?.defaultCity || !events) return [];
    const lat = user.defaultCity.lat;
    const lng = user.defaultCity.lng;

    const radiusInM = 200 * 1000;
    const center: [number, number] = [lat, lng];
    return events.filter((event) => {
      if (!event?.location?.lat || !event?.location?.lng) return false;
      if (event.cancelled) return false;

      const distanceInKm = distanceBetween(
        [event.location.lat, event.location.lng],
        center,
      );
      const distanceInM = distanceInKm * 1000;
      return distanceInM <= radiusInM;
    });
  },
);

export const selectFilteredEvents = (filter: ArkSearchBarFilter) => createSelector(
  selectAllEvents,
  (events) => {
    if (!events) return [];
    if (
      !filter ||
      (!filter.gameType?.length &&
      !filter.online &&
      !filter.exactDate)
    ) return events;

    return events
      .filter((event) =>
        filter.gameType?.length ?
        filter.gameType.some((type) => type === event.gameType) :
        true,
      )
      .filter((event) =>
        filter.online === true ?
        event.online :
        true,
      )
      .filter((event) =>
        filter.exactDate ?
        event.dateStart.toDateString() === filter.exactDate.toDateString() :
        true,
      );
  },
);
